<script>
  export let bpm
</script>

<span class="flex-grow mw-20em">
  <input type="range" bind:value={bpm} name="bpm" min="40" max="180">
  <span class="bpm">{bpm}
    <label for="bpm">bpm</label>
  </span>
</span>

<style>
.bpm {
  display: block;
  text-align: center;
}

.flex-grow {
  flex-grow: 1;
}

.mw-20em {
  max-width: 20em;
}

input {
  --track-color: var(--button-bg);
  --track-color--active: var(--button-bg-dark);
  --thumb-color: var(--text-color);
}

input[type=range] {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  margin-top: 1.2rem;
  cursor: pointer;
  background: var(--track-color);
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background: linear-gradient(30deg, transparent 2%, var(--thumb-color) 20%, var(--thumb-color) 80%, transparent 98%);
  box-shadow: 0 1px 2px -1px var(--thumb-color);
  cursor: pointer;
  transform: translateY(-50%);
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--track-color);
}
</style>
