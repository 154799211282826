<script>
  import Icon from 'fa-svelte'
  import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt'
let shareIcon = faShareAlt

  function share() {
    let url = window.location.href
    if (navigator.share) {
      navigator.share({
        url
      })
        .catch((err) => {
          if (err.name === 'AbortError') {
            return
          } else {
            console.error(err)
          }
        })
    } else {
      copyToClipboard(url)
    }
  }

  let copied
  function copyToClipboard(str) {
    let el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    copied = true
    setTimeout(() => {
      copied = false
    }, 2000)
  }
</script>

<button on:click="{share}"><Icon icon={shareIcon} /> <br><small>share</small></button>
{#if copied}
  <div class="success">Copied URL to clipboard</div>
{/if}

<style>
button {
  margin: 0;
  margin-left: calc(8 * var(--square-size));
  height: var(--square-size);
  width: var(--square-size);
  align-self: center;
  text-align: center;
  padding: 0;
  line-height: 1;
}

.success {
  position: absolute;
  z-index: 2;
  top: 0;
  background: var(--body-bg);
  border: solid 3px;
  padding: 1em;
}
</style>


