export const beats = 16

export const channels = [
  { beats: [1,0,0,1,0,0,0,1,0,1,0,1,0,0,0,0], buffer: null, sample: '', currentBeat: 0, lastTime: null },
  { beats: [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0], buffer: null, sample: '', currentBeat: 0, lastTime: null },
  { beats: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], buffer: null, sample: '', currentBeat: 0, lastTime: null },
  { beats: [1,0,1,0,1,1,1,1,3,1,2,1,1,3,1,1], buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
  { beats: new Array(beats).fill(0), buffer: null, sample: '', currentBeat: 0 , lastTime: null },
]

export const kits = [
  { name: '808', samples: [
    { name: 'kick', path: 'bass_drum.wav' },
    { name: 'snare', path: 'snare_drum.wav' },
    { name: 'open hat', path: 'o_hi_hat.wav' },
    { name: 'closed hat', path: 'cl_hi_hat.wav' },
  ]},
  { name: 'Reggaeton', samples: [
    { name: 'kick', path: 'r_kick.wav' },
    { name: 'snare', path: 'r_snare.wav' },
    { name: 'tom', path: 'mid_tom.wav' },
    { name: 'hat', path: 'r_hat_it.wav' },
  ]},
  { name: 'House 808', samples: [
    { name: 'kick', path: 'bass_drum.wav' },
    { name: 'clap', path: 'hand_clap.wav' },
    { name: 'snare', path: 'snare_drum.wav' },
    { name: 'closed hat', path: 'cl_hi_hat.wav' },
  ]},
  { name: 'Real Drums', samples: [
    { name: 'kick', path: 'pearlkit-kick.wav' },
    { name: 'snare', path: 'pearlkit-snare1.wav' },
    { name: 'ride', path: 'pearlkit-ride1.wav' },
    { name: 'hat', path: 'pearlkit-hihat.wav' },
    { name: 'ride bell', path: 'pearlkit-ridebell.wav' },
  ]},
  { name: '808 Full', samples: [
    { name: 'kick', path: 'bass_drum.wav' },
    { name: 'snare', path: 'snare_drum.wav' },
    { name: 'hand clap', path: 'hand_clap.wav' },
    { name: 'closed hat', path: 'cl_hi_hat.wav' },
    { name: 'open hat', path: 'o_hi_hat.wav' },
    { name: 'cymbal', path: 'cymbal.wav' },
    { name: 'cowbell', path: 'cowbell.wav' },
    { name: 'hi conga', path: 'hi_conga.wav' },
    { name: 'low conga', path: 'low_conga.wav' },
    { name: 'hi tom', path: 'hi_tom.wav' },
    { name: 'low tom', path: 'low_tom.wav' },
  ]},
  { name: '8-bit', samples: [
    { name: 'kick', path: 'SK1_BD.wav' },
    { name: 'snare', path: 'SK1_SD.wav' },
    { name: 'snare 2', path: 'SK1_SD2.wav' },
    { name: 'open hat', path: 'SK1_OH.wav' },
    { name: 'closed hat', path: 'SK1_CH.wav' },
    { name: 'hi tom', path: 'SK1_HT.wav' },
    { name: 'mid tom', path: 'SK1_MT.wav' },
    { name: 'low tom', path: 'SK1_LT.wav' },
  ]},
]

export const presets = [
  {
    name: 'Blank grid',
    beats: [
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    ]
  },
  {
    name: 'Juug #1',
    kit: '808',
    bpm: 70,
    beats: [
      [1,0,0,1,0,0,0,1,0,1,0,1,0,0,0,0],
      [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [1,0,1,0,1,1,1,1,3,1,2,1,1,3,1,1],
    ]
  },
  {
    name: 'Reggaeton ton',
    kit: 'Reggaeton',
    bpm: 100,
    beats: [
      [1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0],
      [0,0,0,1,0,0,1,0,0,0,0,1,0,0,1,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,1,0,0,1,0,0,1,0,0,1,0,0,0,0,0],
    ]
  },
  {
    name: 'Juug House',
    kit: 'House 808',
    bpm: 128,
    beats: [
      [1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0],
      [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0],
      [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0],
      [0,0,1,0,0,0,1,0,0,0,1,0,0,1,1,0],
    ]
  },
  {
    name: '8beat',
    kit: '8-bit Casio',
    bpm: 77,
    beats: [
      [1,0,0,1,0,0,1,0,0,0,0,1,0,0,0,1],
      [0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0],
      [0,0,0,0,0,1,0,0,1,0,0,0,0,1,0,0],
      [1,0,1,0,1,0,0,1,0,1,0,0,1,0,1,1],
      [0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0],
      [4,1,0,1,0,0,0,2,1,0,0,1,0,1,0,0],
      [0,0,0,0,0,2,0,0,0,1,0,0,1,0,0,0],
      [0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0]
    ]
  }
]

