// from https://paulbakaus.com/tutorials/html5/web-audio-on-ios/
// and https://github.com/pavle-goloskokovic/web-audio-touch-unlock
export default function(audioCtx) {
  function iosUnlockAudio() {
    audioCtx.resume().then(function() {
      const buffer = audioCtx.createBuffer(1, 1, 22050)
      const source = audioCtx.createBufferSource()

      source.buffer = buffer
      source.connect(audioCtx.destination)
      if (source.start) {
        source.start(0)
      } else if (source.play) {
        source.play(0)
      } else if (source.noteOn) {
        source.noteOn(0)
      }

      window.removeEventListener('touchstart', iosUnlockAudio, false)
      window.removeEventListener('touchend', iosUnlockAudio, false)
    })
  }

  if (audioCtx.state === 'suspended' && 'ontouchstart' in window) {
    window.addEventListener('touchstart', iosUnlockAudio, false)
    window.addEventListener('touchend', iosUnlockAudio, false)
  }
}

