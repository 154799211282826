<script>
  import Header from './Header.svelte'
  import Footer from './Footer.svelte'
  import BpmSlider from './BpmSlider.svelte'
  import Icon from 'fa-svelte'
  import iosUnlockAudio from './polyfills'
  import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
  import { faStop } from '@fortawesome/free-solid-svg-icons/faStop'

  import { beats, channels as chans, kits, presets } from './constants'

  let channels = chans

  let playIcon = faPlay

	let bpm = 69

  let kit = kits[0]

  $: activeChannels = channels.slice(0, kit.samples.length)

  function findKit(name) {
    return kits.find(k => k.name === name) || kits[0]
  }

	let audioCtx = new window.AudioContext()
  let nextNoteTime = 0.0

  // ios fallback nonsense
  iosUnlockAudio(audioCtx)

  function serialize() {
    let beats = activeChannels.map(a => {
      let int1 = parseInt(a.beats.slice(0, 8).join(''), 16)
      let int2 = parseInt(a.beats.slice(8, 16).join(''), 16)
      return int1.toString(36) + '-' + int2.toString(36)
    })
    return beats.join('_')
  }

  function deserialize(str) {
    let arr = str
      .split('_')
      .map(n => {
        let [a, b] = n.split('-').map(val => {
          return parseInt(val, 36).toString(16).padStart(8, 0)
        })
        return `${a}${b}`.split('').map(Number)
      })

    return arr
  }

  function saveState() {
    let params = new URLSearchParams()

    params.set('bpm', bpm)
    params.set('kit', kit.name)
    params.set('beats', serialize())

    history.replaceState({}, null, `?${params.toString()}`)
  }

  function loadState() {
    let params = new URLSearchParams(window.location.search)

    for (let [key, value] of params) {
      if (key === 'bpm') {
        bpm = value
      }

      if (key === 'kit') {
        kit = findKit(value)
      }

      if (key === 'beats') {
        let allBeats = deserialize(value)
        for (let i = 0; i < allBeats.length; i++) {
          channels[i].beats = allBeats[i]
        }
      }
    }

    let sounds = kit.samples.map(s => `sounds/${s.path}`)

    loadSounds(sounds)
  }

  function loadPreset(preset) {
    clearSamples()
    bpm = preset.bpm || bpm
    kit = findKit(preset.kit) || kit
    preset.beats.forEach((beats, i) => {
      channels[i].beats = beats
    })
  }

  function loadSound(sound, channel) {
    let req = new XMLHttpRequest()
    req.open('GET', sound, true)
    req.responseType = 'arraybuffer'
    req.onload = function() {
      audioCtx.decodeAudioData(req.response, function(buffer) {
        channel.sample = sound 
        channel.buffer = buffer
      })
    }
    req.send()
  }

  function clearSamples() {
    nextNoteTime = audioCtx.currentTime
    channels.forEach(c => {
      c.buffer = null
      c.currentBeat = 0
      c.lastTime = nextNoteTime
    })
  }

  function loadSounds(sounds) {
    sounds.forEach((s, i) => {
      channels[i].sample = s
    })
  }
	
  function playSound(channelIndex, time) {
    let channel = channels[channelIndex]
    let sample = `sounds/${kit.samples[channelIndex].path}`

    let { buffer } = channel

    try {
      if (!buffer) {
        loadSound(sample, channel)
      }
      let source = audioCtx.createBufferSource()
      source.buffer = buffer
      source.connect(audioCtx.destination)
      source.start(time)
    } catch(e) {
      new Audio(sample).play()
    }
  }

	function scheduleBeats() {
    let currentTime = audioCtx.currentTime
    let scheduleAheadTime = 0.1 // in sec

    activeChannels.forEach((channel, channelIndex) => {
      let { buffer, sample, currentBeat }  = channel

      while (channel.lastTime < currentTime + scheduleAheadTime) {
        let hits = channel.beats[currentBeat]
        let secondsPerBeat = 60 / bpm
        
        if (hits === 0) {
          channel.lastTime += (0.25 * secondsPerBeat) 
        }
        for (let i=0; i < hits; i++) {
          channel.lastTime += (0.25 * secondsPerBeat) / hits
          playSound(channelIndex, channel.lastTime)
        }

        channel.currentBeat = (channel.currentBeat + 1) % channel.beats.length
      }
    })
  }

  function updateChannels() {
    channels = channels

    requestAnimationFrame(updateChannels)
  }

  requestAnimationFrame(updateChannels)

  let playing = false
  let timerId
  function play() {
    playIcon = faStop

    nextNoteTime = audioCtx.currentTime
    activeChannels.forEach(c => {
      c.currentBeat = 0
      c.lastTime = nextNoteTime
    })

    timerId = setInterval(scheduleBeats, 100)
    playing = true
  }

  function stop() {
    clearInterval(timerId)
    playing = false
    playIcon = faPlay

    channels.forEach(c => {
      c.currentBeat = 0
    })

  }

  function togglePlay() {
    if (playing) {
      stop()
    } else {
      play()
    }
  }

  let hitsPerBeat = 1
  function toggleBeat(channelIndex, beatIndex) {
    if (channels[channelIndex].beats[beatIndex] > 0) {
      channels[channelIndex].beats[beatIndex] = 0
    } else {
      channels[channelIndex].beats[beatIndex] = hitsPerBeat
    }
  }

  loadState()
  // autosave
  setInterval(saveState, 1000)
</script>

<div class="container">
  <Header />
  <div class="juug-container">
  <div class="grid">
	{#each activeChannels as channel, j}
		<div class="row">
			{#each channel.beats as beat, i}
				<button
          class="square"
          class:active="{playing && channel.currentBeat == (i + 1) % 16}"
          class:checked="{beat}"
          class:doublet="{beat === 2}"
          class:triplet="{beat === 3}"
          class:quad="{beat === 4}"
          on:click={e => toggleBeat(j, i)}>
        </button>
			{/each}
    </div>
	{/each}
  </div>
  <div class="controls">
    <button on:click={togglePlay} class="play">
      <Icon icon={playIcon} />
    </button>
    <BpmSlider bind:bpm={bpm} />
    <span class="ml-1">
      <select bind:value={hitsPerBeat}>
        <option value={1}>1 hit</option>
        <option value={2}>2 hits</option>
        <option value={3}>3 hits</option>
        <option value={4}>4 hits</option>
      </select>
    </span>
  </div>
  
  <div class="controls">
    <label for="kit" style="margin-right: 0.5em">Kit</label>
    <select bind:value={kit} name="kit" on:change="{clearSamples}">
      {#each kits as k}
        <option value={k}>
          {k.name}
        </option>
      {/each}
    </select>
  </div>
  <div class="controls">
    Change kit, hits, and tempo to make 🔥 beats
  </div>
  <div class="controls">
    <div>
      <h3>Saved Beats</h3>
      {#each presets as preset}
        <ul>
          <li class="saved-beat">
            <a href="{preset.href}" on:click="{() => loadPreset(preset)}">{preset.name}</a>
          </li>
        </ul>
      {/each}
    </div>
  </div>
  </div>
  <Footer />
</div>

<style>
  :root {
    --square-size: 6.25vmin;
  }

  .container {
    margin: auto;
  }

  .juug-container {
    min-height: calc(100vh - 112px);
  }

  button {
    width: 3em;
    height: 3em;
    line-height: 3;
    padding: 0;
  }

  option {
    background: var(--body-bg)
  }

  option:last-child {
    border-radius: 0 1em 1em 0;
  }

  .grid {
    padding: 0 0.25rem;
  }

	.row {
    display: flex;
		align-items: center;
		justify-items: stretch;
	}
	
	.square {
    flex-grow: 1;
    width: var(--square-size);
    height: var(--square-size);
    margin: 0;
    position: relative;
    border-radius: 4px;
    border: solid 2px var(--body-bg);
    background-color: var(--button-bg);
	}

	.square.checked {
    --bg-1: #4c9;
    --bg-2: #2a7;
		background-color: var(--bg-1);
	}

  .square.doublet {
    background: linear-gradient(to right, var(--bg-1), var(--bg-2), var(--bg-1), var(--bg-2));
  }

  .square.triplet {
    background: linear-gradient(to right,
      var(--bg-1), var(--bg-2),
      var(--bg-1), var(--bg-2),
      var(--bg-1), var(--bg-2));
  }

  .square.quad {
    background: linear-gradient(to right,
      var(--bg-1), var(--bg-2),
      var(--bg-1), var(--bg-2),
      var(--bg-1), var(--bg-2),
      var(--bg-1), var(--bg-2));
  }

	.square.active {
    filter: brightness(125%);
	}

  .square.active.checked {
    filter: brightness(150%);
    transition: transform 0.05s linear;
    transform: scale(1.05);
    z-index: 1;
	}

  .controls {
    margin: 1em 0 2em;
    padding: 0 1em;
    display: flex;
    align-items: center;
  }

  .saved-beat {
    margin: 0.5em 0;
  }

  .play {
    font-size: 2em;
    line-height: 1;
    width: 1em;
    height: 1em;
    margin: 0 1rem 0 0;
  }

  .input-wrapper {
    display: flex;
    margin: 0 1em;

  }

  .ml-1 {
    margin-left: 1rem;
  }
</style>

